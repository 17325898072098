<template>
	<b-modal
		id="add-transportation"
		title="Add Transportation"
		ref="modal"
		ok-title="Add"
		@ok="handleOk"
		@show="onReset"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-12">
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Company">
							<v-select
								name="Company"
								class="style-chooser"
								label="text"
								:options="companyOptions"
								:reduce="(company) => company.value"
								v-model="selCompany"
								v-validate="'selectRequired'"
							>
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">{{
								errors.first('Company')
							}}</span>
						</b-form-group>
					</b-col>

					<b-col lg="12" md="12" sm="12">
						<b-form-group
							label="Plate No/CR/OR"
							label-for="plateNo"
							description
						>
							<b-form-input
								id="plateNo"
								name="plate no"
								type="text"
								v-model="form.plateNo"
								v-validate="{
									required: true,
									regex: /^[a-zA-Z0-9_ -]{6,12}$/,
								}"
								maxlength="12"
								placeholder="Plate No/Certification of Registration (CR)/OR"
							/>
							<span v-show="errors.has('plate no')" class="help-block">{{
								errors.first('plate no')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="my-12">
					<b-col lg="12" md="12" sm="12">
						<b-form-group
							label="Description:"
							label-for="description"
							description
						>
							<b-form-textarea
								name="Description"
								type="text"
								v-model="form.description"
								maxlength="200"
								:rows="3"
								placeholder="Description"
							/>
							<span v-show="errors.has('Description')" class="help-block">{{
								errors.first('Description')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'add-transportation',
	components: {
		Loading,
	},
	props: {
		companyFilter: {
			type: Object,
			required: true,
		},
		companyOptions: {
			type: Array,
			required: true,
		},
		allTransportationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: {
				plateNo: '',
				description: '',
				company: '',
				companyId: '',
				isActive: 'true',
			},
			selCompany: config.retrieveCompanyItems,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		plateNo() {
			return this.form.plateNo;
		},
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}
			if (this.form.plateNo.length > 0 && this.hasExistingTransportation()) {
				this.$toaster.warning(
					'Transportation with plate no. ' +
						this.form.plateNo +
						' already exists.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Removes excess whitespace
			this.form.plateNo = ValidationUtil.removeExcessWhiteSpace(
				this.form.plateNo
			);
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);

			this.handleSubmit();
		},
		hasExistingTransportation() {
			let hasExisting = false;
			let transportationsArr = Object.values(this.allTransportationsObj);
			for (const transportation of transportationsArr) {
				if (transportation.plateNo === this.form.plateNo) {
					hasExisting = true;
					break;
				}
			}
			return hasExisting;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			// assign the selected company to the user
			this.updateCompanySelection();

			try {
				let result = await this.$store.dispatch('addTransportation', this.form);

				// hide loading indicator
				this.isLoading = false;

				if (result.isSuccess) {
					this.$toaster.success(
						`New Transportation "${this.plateNo}" was created successfully.`
					);
					EventBus.$emit('onCloseAddTransportation', result.data);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error creating transportation "${this.plateNo}"". Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error creating transportation "${this.plateNo}"". Please try again.`
				);
			}
		},
		updateCompanySelection() {
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;
		},
		onReset() {
			/* Reset our form values */
			this.form.plateNo = '';
			this.form.description = '';
			this.form.company = null;
			this.form.companyId = null;
			this.form.isActive = 'true';

			// init company
			if (this.companyFilter.id) {
				this.selCompany = this.companyFilter;
			} else {
				this.selCompany = config.companyDefaultValue;
			}

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style>
</style>


