<template>
	<b-modal
		id="activate-transportation"
		title="Transportation Activation"
		ok-title="Save"
		ref="modal"
		@hide="handleClose"
		@ok="handleOk"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<div class="confirmMessage">
			Are you sure you want to
			<b>{{ isActive === 'true' ? 'deactivate' : 'activate' }}</b>
			this transportation with plate no <b>"{{ plateNo }}"</b>?
		</div>
	</b-modal>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'activate-transportation',
	components: { Loading },
	data() {
		return {
			selTransportation: {},
			isActive: true,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		plateNo() {
			return this.selTransportation.plateNo;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelTransportation', (selTransportation) => {
			this.selTransportation = selTransportation;
			this.isActive = selTransportation.isActive;
		});
	},
	methods: {
		toggleStatus(isActive) {
			let status = 'true';
			if (isActive === 'true') {
				status = 'false';
			}
			return status;
		},
		resetStatus() {
			this.selTransportation.isActive = this.isActive;
			this.$store.commit('SET_CURR_TRANSPORTATION', this.selTransportation);
		},
		handleClose() {
			this.resetStatus();
		},

		async validateActivation() {
			let isValid = true;

			const dispatches = await this.$store.dispatch(
				'fetchDispatchesByPlateNo',
				this.plateNo
			);

			let filteredDispatches = _.filter(dispatches, (o) => {
				return o.status === 'Draft' || o.status == 'In-Transit';
			});

			if (!_.isEmpty(filteredDispatches)) {
				this.$toaster.warning(
					'Deactivation unsuccessful! Transportation is currently in operation.'
				);
				isValid = false;
			}

			return isValid;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			if (await this.validateActivation()) {
				await this.handleSubmit();
			}
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			// update the status
			this.selTransportation.isActive = this.toggleStatus(this.isActive);

			try {
				let result = await this.$store.dispatch(
					'updateTransportation',
					this.selTransportation
				);

				// hide loading indicator
				this.isLoading = false;
				this.$refs.modal.hide();

				if (result.isSuccess) {
					let successMsg = '';
					if (result.data.isActive === 'true') {
						successMsg = `Transportation "${this.plateNo}" is now activated!`;
					} else {
						successMsg = `Transportation "${this.plateNo}" is now deactivated!`;
					}

					this.$toaster.success(successMsg);
					EventBus.$emit('onCloseEditTransportation', result.data);
				} else {
					let errorMsg = '';
					if (this.toggleStatus(this.isActive) === 'true') {
						errorMsg = `There's a problem encountered during transportation activation of "${this.plateNo}"`;
					} else {
						errorMsg = `There's a problem encountered during transportation deactivation of "${this.plateNo}"`;
					}

					this.resetStatus();
					this.$toaster.error(errorMsg);
				}
			} catch (error) {
				let errorMsg = '';
				if (this.toggleStatus(this.isActive) === 'true') {
					errorMsg = `There's a problem encountered during transportation activation of "${this.plateNo}"`;
				} else {
					errorMsg = `There's a problem encountered during transportation deactivation of "${this.plateNo}"`;
				}

				this.resetStatus();
				this.$toaster.error(errorMsg);

				// hide loading indicator
				this.isLoading = false;
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelTransportation');
	},
};
</script>

<style scoped>
</style>

