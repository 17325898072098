import { StringUtil } from './stringUtil';
import config from '../config/env-constants';
import _ from 'lodash';


export const loadText = (context, text, x, y, maxWidth, lineHeight, color) => {
  let words = text.split(' ');
  let line = '';
  let linesAdded = 0;
  let hasEllipsis = false;

  for (let n = 0; n < words.length; n++) {
    let testLine = line + words[n] + ' ';
    let metrics = context.measureText(testLine);
    let testWidth = metrics.width;

    context.fillStyle = color;
    if (testWidth > maxWidth && n > 0) {
      linesAdded++;

      if (linesAdded >= 3) {
        hasEllipsis = true;
        linesAdded -= 1;
      } else {
        context.fillText(line, x, y);
        y += lineHeight;
        line = words[n] + ' ';
      }
    } else {
      line = testLine;
    }
  }

  if (hasEllipsis) {
    line += '...';
    hasEllipsis = false;
  }

  // Truncate string if more than 30 characters
  line = StringUtil.truncate(line, config.maxQRCodeStrLength);

  context.fillText(line, x, y);

  return linesAdded;
};

export const loadImages = (sources, callback) => {
  let images = {};
  let loadedImages = 0;
  let numImages = _.size(sources);

  for (let src in sources) {
    images[src] = new Image();
    images[src].onload = function () {
      if (++loadedImages >= numImages) {
        callback(images);
      }
    };
    images[src].src = sources[src];
  }
}